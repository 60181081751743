<template>
  <main class="view-container-card min-vh-100 d-flex flex-column justify-content-around">
    <div class="card-info-container">
      <img style="border-radius: 28px;box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.48);" :src="$theme.media.card"/>
      <div class="card-barcode-container">
        <p>{{ $store.state.loggedUser.personal_identity.first_name }} {{ $store.state.loggedUser.personal_identity.last_name }}</p>
        <img id="barcode_image_container">
      </div>
    </div>
    <img class="d-none d-lg-block" :src="$theme.media.logoDark">
  </main>
</template>
<script>
import JSBarcode from 'jsbarcode'

export default {
  name: "Card",
  mounted() {
    this.renderBarcode()
  },
  methods: {
    renderBarcode() {
      JSBarcode('#barcode_image_container', this.$store.state.loggedUser.card_number, {})
    }
  }
}
</script>

<style scoped lang="scss">
img {
  width: fit-content;
  z-index: 1;
  max-width: 100%;
}
.view-container-card {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  @extend %view-container-card;
}
.card-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @extend %card-info-container;
}
.card-barcode-container {
  width: fit-content;

  @extend %card-barcode-container;
}
</style>
