
import {createRouter, createWebHistory} from 'vue-router'

import Home from '../views/unauthenticated/Home.vue'
import Login from "../views/unauthenticated/Login";
import Register from "../views/unauthenticated/Register";
import PasswordReset from "../views/unauthenticated/PasswordReset";
import Start from "../views/authenticated/Start";
import Offers from "../views/authenticated/Offers";
import NotificationsPage from "../views/authenticated/notifications/NotificationsPage";
import NotificationPage from "../views/authenticated/notifications/NotificationPage";
import RewardsCatalogue from "../views/authenticated/rewards/RewardsCatalogue";
import RewardsCollected from "../views/authenticated/rewards/RewardsCollected";
import Reward from "../views/authenticated/rewards/Reward";
import EventsCatalogue from "../views/authenticated/events/EventsCatalogue";
import Event from "../views/authenticated/events/Event";
import Card from "../views/authenticated/Card";
import ReceiptScanPage from "../views/authenticated/receipts/ReceiptScanPage";
import ReceiptsIndex from "../views/authenticated/receipts/ReceiptsIndex";
import Receipt from "../views/authenticated/receipts/Receipt";
import Account from "../views/authenticated/account/Account";
import PersonalData from "../views/authenticated/account/PersonalData";
import Password from "../views/authenticated/account/Password";
import Agreements from "../views/authenticated/account/Agreements";
import IssuesIndex from "../views/authenticated/account/issues/IssuesIndex";
import Issue from "../views/authenticated/account/issues/Issue";
import LoyaltyAccountChanges from "../views/authenticated/account/LoyaltyAccountChanges";
import Page from "../views/unauthenticated/Page";
import RegisterConfirm from "@/views/unauthenticated/RegisterConfirm";
let routes = [
  /**
   * Routes for unauthenticated users
   */
  {
    path: '/_=_',
    name: 'SocialLoginCallback',
    component: Home,
    meta: {
      disableNav: true,
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      disableNav: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      disableNav: true,
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      disableNav: true,
    }
  },
  {
    path: '/confirm_user/:code',
    name: 'RegisterConfirm',
    component: RegisterConfirm,
    meta: {
      disableNav: true,
    }
  },
  {
    path: '/password/reset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {
      disableNav: true,
    }
  },
  {
    path: '/page/:uuid',
    name: 'Page',
    component: Page,
    meta: {
      disableNav: true,
    }
  },

  /**
   * Routes for authenticated users
   */
  {
    path: '/start',
    name: 'Start',
    component: Start,
  },
  {
    path: '/card',
    name: 'Card',
    component: Card,
  },
  {
    path: '/offers',
    name: 'Offers',
    component: Offers,
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: NotificationsPage,
  },
  {
    path: '/notifications/:uuid',
    name: 'NotificationPage',
    component: NotificationPage,
  },
  {
    path: '/rewards',
    name: 'RewardsCatalogue',
    component: RewardsCatalogue,
  },
  {
    path: '/rewards/collected',
    name: 'RewardsCollected',
    component: RewardsCollected,
  },
  {
    path: '/rewards/:uuid',
    name: 'Reward',
    component: Reward,
  },
  {
    path: '/events',
    name: 'EventsCatalogue',
    component: EventsCatalogue,
  },
  {
    path: '/events/:uuid',
    name: 'Event',
    component: Event,
  },
  {
    path: '/receipts/scan',
    name: 'ReceiptScanPage',
    component: ReceiptScanPage,
  },

  {
    path: '/account',
    name: 'Account',
    component: Account,
    children: [
      {
        path: '/account/personal-data',
        name: 'PersonalData',
        component: PersonalData,
      },
      {
        path: '/account/password',
        name: 'Password',
        component: Password,
      },
      {
        path: '/account/agreements',
        name: 'Agreements',
        component: Agreements,
      },
      {
        path: '/account/receipts',
        name: 'ReceiptsIndex',
        component: ReceiptsIndex,
      },
      {
        path: '/account/receipts/:uuid',
        name: 'Receipt',
        component: Receipt,
      },
      {
        path: '/account/loyalty-account-changes',
        name: 'LoyaltyAccountChanges',
        component: LoyaltyAccountChanges,
      },
      {
        path: '/account/issues',
        name: 'IssuesIndex',
        component: IssuesIndex,
      },
      {
        path: '/account/issues/:uuid',
        name: 'Issue',
        component: Issue,
      },
      {
        path: '/account/page/:uuid',
        name: 'AccountPage',
        component: Page,
      },
    ],
  },
]

if (window.location.hostname.includes('sadyba')) {
  routes.push({
    path: '/deklaracja/Deklaracja_najemcy_multiapka_Sadyba_2024.pdf',
    name: 'DeclarationSadyba',
    beforeEnter() {
      window.location.href = '/deklaracja/Deklaracja_najemcy_multiapka_Sadyba_2024.pdf';
    }
  });
} else if (window.location.hostname.includes('lublinplaza')) {
  routes.push({
    path: '/deklaracja/Deklaracja_najemcy_multiapka_Lublin_Plaza_2024.pdf',
    name: 'DeclarationLublin',
    beforeEnter() {
      window.location.href = '/deklaracja/Deklaracja_najemcy_multiapka_Lublin_Plaza_2024.pdf';
    }
  });
} else if (window.location.hostname.includes('poznanplaza')) {
  routes.push({
    path: '/deklaracja/Deklaracja_najemcy_multiapka_Poznan_Plaza_2024.pdf',
    name: 'DeclarationPoznan',
    beforeEnter() {
      window.location.href = '/deklaracja/Deklaracja_najemcy_multiapka_Poznan_Plaza_2024.pdf';
    }
  });
} else if (window.location.hostname.includes('rudaslaskaplaza')) {
  routes.push({
    path: '/deklaracja/Deklaracja_najemcy_multiapka_Ruda_Slaska_Plaza_2024.pdf',
    name: 'DeclarationPoznan',
    beforeEnter() {
      window.location.href = '/deklaracja/Deklaracja_najemcy_multiapka_Ruda_Slaska_Plaza_2024.pdf';
    }
  });
} else if (window.location.hostname.includes('rybnikplaza')) {
  routes.push({
    path: '/deklaracja/Deklaracja_najemcy_multiapka_Rybnik_Plaza_2024.pdf',
    name: 'DeclarationPoznan',
    beforeEnter() {
      window.location.href = '/deklaracja/Deklaracja_najemcy_multiapka_Rybnik_Plaza_2024.pdf';
    }
  });
} else if (window.location.hostname.includes('plazasosnowiec')) {
  routes.push({
    path: '/deklaracja/Deklaracja_najemcy_multiapka_Sosnowiec_Plaza_2024.pdf',
    name: 'DeclarationPoznan',
    beforeEnter() {
      window.location.href = '/deklaracja/Deklaracja_najemcy_multiapka_Sosnowiec_Plaza_2024.pdf';
    }
  });
}
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function () {
    return { x: 0, y: 0 }
  }
})

export default router
